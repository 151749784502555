<template>
  <div class="bg-colorGreen">
    <b-container>
      <div class="d-flex align-items-center justify-content-end py-1">
        <div class="mr-2">
          <img src="@/assets/images/icons/phone.svg" class="mr-50" />
          <a
            :href="whatsappLink"
            target="_blank"
            class="text-white"
            style="text-decoration: none"
          >
            +92 319 6048487
          </a>
        </div>
        <div class="mr-2">
          <img src="@/assets/images/icons/email-02.svg" class="mr-50" />
          <span class="text-white" style="text-decoration: none">
            info@medasktours.com
          </span>
        </div>

        <!-- <VueSelectPaginatedMultiple
          placeholder="Search"
          name="Search"
          label="name"
          searchBy="search"
          :getListMethod="getSearchView"
          :showLabel="false"
          @setMethod="setSearchItem"
          customClass="w-25"
          customFormGroupClass="mb-0"
          class="mr-2"
        /> -->

        <!-- <b-form-input
              size="sm"
              class="mr-sm-2"
              placeholder="Search"
              @input.native="onSearchChange($event)"
            ></b-form-input> -->
        <!-- <b-button
              size="sm"
              class="my-2 my-sm-0 mr-2"
              type="submit"
              variant="outline-light"
              >Search</b-button
            > -->

        <!-- <b-dropdown id="language-dropdown" variant="outline">
          <template #button-content>
            <span class="text-white">
              <img src="@/assets/images/icons/translator.svg" />
            </span>
            <span class="ml-50 text-white">{{ currentLocale.name }}</span>
          </template>
          <b-dropdown-item
            v-for="localeObj in locales"
            :key="localeObj.locale"
            @click="$i18n.locale = localeObj.locale"
          >
            <span class="ml-50">{{ localeObj.name }}</span>
          </b-dropdown-item>
        </b-dropdown> -->
        <!-- <div class="mr-1" v-show="!this.getLoggedInUser">
          <b-link
            :to="{ name: 'Login' }"
            class="font-weight-bolder text-colorText"
          >
            <img src="@/assets/images/icons/user.svg" class="mr-50" />
            <span class="text-white">Login</span>
          </b-link>
        </div>
        <div class="mr-1" v-show="!getLoggedInUser">
          <b-link
            :to="{ name: 'SignUp' }"
            class="font-weight-bolder text-colorText"
          >
            <img
              src="@/assets/images/icons/signUp.svg"
              class="mr-50 rounded"
              width="30"
            />
            <span class="text-white">Signup</span>
          </b-link>
        </div> -->

        <div class="mr-3" v-show="getLoggedInUser">
          <b-link
            :to="{ name: 'PatientDashboard' }"
            class="font-weight-bolder text-colorText"
            v-if="user && user.role === 2"
          >
            <img src="@/assets/images/icons/user.svg" class="mr-50" />
            <span class="text-white">Dashboard</span>
          </b-link>

          <b-link
            :to="{ name: 'DoctorDashboard' }"
            class="font-weight-bolder text-colorText"
            v-else-if="user && user.role === 3"
          >
            <img src="@/assets/images/icons/user.svg" class="mr-50" />
            <span class="text-white">Dashboard</span>
          </b-link>

          <b-link
            :to="{ name: 'User' }"
            class="font-weight-bolder text-colorText"
            v-else
          >
            <img src="@/assets/images/icons/user.svg" class="mr-50" />
            <span class="text-white">Dashboard</span>
          </b-link>
        </div>

        <div class="mr-3" v-show="this.getLoggedInUser">
          <b-link
            @click="logoutButtonClick()"
            class="font-weight-bolder text-colorText"
          >
            <img src="@/assets/images/icons/user.svg" class="mr-50" />
            <span class="text-white">Logout</span>
          </b-link>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VueSelectPaginatedMultiple from "@/components/ui/VueSelectPaginatedMultiple.vue";

export default {
  components: {
    VueSelectPaginatedMultiple,
  },
  data() {
    return {
      locales: [
        {
          locale: "en",
          name: "English",
        },
        {
          locale: "ur",
          name: "اردو",
        },
      ],
      whatsappNumber: "923196048487",
      user: {
        role: 0,
      },
      search: "",
      searchItem: null,
    };
  },
  methods: {
    ...mapActions({
      logout: "appData/logout",
      getSearchView: "appData/getSearchView",
    }),
    async logoutButtonClick() {
      try {
        const res = await this.logout();
        if (res.status === 204) {
          this.$router.push({ name: "Home" });
        }
      } catch (error) {
        console.log(error);
      }
    },
    setSearchItem(value) {
      this.searchItem = value;
      if (this.searchItem.obj == "Doctor") {
        this.$router.push({ name: "AllDoctor" });
      } else if (this.searchItem.obj == "Department") {
        this.$router.push({
          name: "Treatment",
          query: { id: this.searchItem.id },
        });
      } else if (this.searchItem.obj == "Hospital") {
        this.$router.push({
          name: "Hospital",
          query: { id: this.searchItem.id },
        });
      }
    },
  },
  computed: {
    currentLocale() {
      this.user = this.getLoggedInUser;
      return this.locales.find((l) => l.locale === this.$i18n.locale);
    },
    whatsappLink() {
      return `https://wa.me/${this.whatsappNumber}`;
    },
    ...mapGetters({ getLoggedInUser: "appData/getLoggedInUser" }),
  },
  mounted() {
    this.user = this.getLoggedInUser;
  },
};
</script>

<style scoped></style>
