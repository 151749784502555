<template>
  <div class="bg-white">
    <b-container>
      <b-navbar toggleable="lg" type="light" class="p-0">
        <b-navbar-brand href="#">
          <div>
            <b-link
              :to="{ name: 'Home' }"
              class="font-weight-bolder text-colorText"
            >
              <img src="@/assets/images/logo/medask.png" class="mr-1 logo1" />
            </b-link>
          </div>
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto py-2">
            <b-nav-item class="px-50">
              <b-link
                :to="{ name: 'Home' }"
                class="font-weight-bolder text-colorText"
                ><span class="my-span my-nav-link">Home</span></b-link
              >
            </b-nav-item>

            <b-nav-item-dropdown right class="px-50">
              <template #button-content>
                <span class="font-weight-bolder text-colorText my-span"
                  >Treatments</span
                >
              </template>
              <!-- Use manual column division -->
              <div
                class="dropdown-menu-columns"
                :style="{ width: dynamicColumnWidth }"
              >
                <div
                  v-for="(column, index) in numberOfColumns"
                  :key="index"
                  class="column"
                  :data-column-index="index"
                >
                  <div
                    v-for="(
                      department, departmentIndex
                    ) in getColumnDepartments(index)"
                    :key="departmentIndex"
                  >
                    <b-dropdown-item
                      :to="{ name: 'Treatment', query: { id: department.id } }"
                    >
                      <span class="my-span my-nav-link">{{
                        department.name
                      }}</span>
                    </b-dropdown-item>
                  </div>
                </div>
              </div>
            </b-nav-item-dropdown>

            <b-nav-item-dropdown right class="px-50">
              <template #button-content>
                <span class="font-weight-bolder text-colorText my-span"
                  >Hospitals
                </span>
              </template>
              <b-dropdown-item
                v-for="hospital in hospitals"
                :key="hospital.id"
                :to="{ name: 'Hospital', query: { id: hospital.id } }"
              >
                <span class="my-span my-nav-link">{{ hospital.name }}</span>
              </b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item class="px-50">
              <b-link
                :to="{ name: 'AllDoctor' }"
                class="font-weight-bolder text-colorText"
                ><span class="my-span my-nav-link">Doctors</span></b-link
              >
            </b-nav-item>
            <b-nav-item-dropdown right class="px-50">
              <template #button-content>
                <span class="font-weight-bolder text-colorText my-span"
                  >Services</span
                >
              </template>
              <b-dropdown-item :to="{ name: 'VisaAssistance' }">
                <span class="my-span my-nav-link">Visa Assistance</span>
              </b-dropdown-item>
              <b-dropdown-item :to="{ name: 'Hotel' }">
                <span class="my-span my-nav-link">Hotels</span>
              </b-dropdown-item>
              <b-dropdown-item :to="{ name: 'CarRental' }">
                <span class="my-span my-nav-link">Car Rental</span>
              </b-dropdown-item>
              <b-dropdown-item :to="{ name: 'Interpreter' }">
                <span class="my-span my-nav-link">Interpreter</span>
              </b-dropdown-item>

              <b-dropdown-item :to="{ name: 'Tourism' }">
                <span class="my-span my-nav-link">Tourism</span>
              </b-dropdown-item>
              <b-dropdown-item :to="{ name: 'Insurance' }">
                <span class="my-span my-nav-link">Insurance</span>
              </b-dropdown-item>
              <!-- <b-dropdown-item :to="{ name: 'SafeTravel' }">
                <span class="my-span my-nav-link">Safe Travel</span>
              </b-dropdown-item> -->
            </b-nav-item-dropdown>
            <b-nav-item class="px-50">
              <b-link
                :to="{ name: 'AboutUs' }"
                class="font-weight-bolder text-colorText my-span my-nav-link"
                ><span class="my-span my-nav-link">About Us</span></b-link
              >
            </b-nav-item>
            <b-nav-item class="px-50">
              <b-link
                :to="{ name: 'ContactUs' }"
                class="font-weight-bolder text-colorText my-span my-nav-link"
                ><span class="my-span my-nav-link">Contact Us</span></b-link
              >
            </b-nav-item>

            <!-- <b-nav-item class="px-50">
              <b-link
                :to="{ name: 'Management' }"
                class="font-weight-bolder text-colorText my-span my-nav-link"
                ><span class="my-span my-nav-link">Management</span></b-link
              >
            </b-nav-item> -->

            <!-- <b-nav-item class="px-50">
              <b-link
                :to="getCart.length > 0 ? { name: 'Cart' } : null"
                class="font-weight-bolder text-colorText my-span my-nav-link"
              >
                <feather-icon
                  :badge="getCart.length"
                  class="text-colorText"
                  icon="ShoppingCartIcon"
                  size="24"
                />
              </b-link> 
            </b-nav-item>-->
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  components: {},
  data() {
    return {
      hospitals: [],
      departments: [],
    };
  },
  async mounted() {
    const res_hospitals = await this.getHospitalsPublic();
    this.hospitals = res_hospitals.data;

    const res_departments = await this.getDepartmentsPublic();
    this.departments = res_departments.data;
  },
  methods: {
    ...mapActions({
      getHospitalsPublic: "appData/getHospitalsPublic",
      getDepartmentsPublic: "appData/getDepartmentsPublicNav",
    }),

    getColumnDepartments(columnIndex) {
      const maxItemsPerColumn = 15;
      const startIndex = columnIndex * maxItemsPerColumn;
      const endIndex = Math.min(
        (columnIndex + 1) * maxItemsPerColumn,
        this.departments.length
      );
      return this.departments.slice(startIndex, endIndex);
    },
  },

  computed: {
    ...mapGetters({ getCart: "appData/getCart" }),
    // Compute the number of columns based on the departments and max items per column
    numberOfColumns() {
      const maxItemsPerColumn = 15;
      return Math.ceil(this.departments.length / maxItemsPerColumn);
    },
    dynamicColumnWidth() {
      const baseWidth = 300; // Base width in pixels
      const numberOfColumns = this.numberOfColumns;
      const totalWidth = baseWidth * numberOfColumns;
      return totalWidth + "px";
    },
  },
};
</script>

<style>
.my-span {
  font-size: 1.3rem;
}

.my-nav-link.active,
.my-nav-link:hover {
  color: #06bd46 !important;
  background-color: transparent !important;
}

.logo1 {
  width: 100px;
  height: auto;
}

.dropdown-menu-columns {
  display: flex;
  flex-wrap: wrap;
}

.column {
  width: 50%; /* Adjust as needed */
}

@media (max-width: 768px) {
  .column {
    width: 100%; /* Full width on smaller screens */
  }
}
</style>
