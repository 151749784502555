<template>
  <div class="my-bg">
    <b-container>
      <b-row class="d-flex align-items-start justify-content-center">
        <b-col md="3">
          <div class="pt-3">
            <img
              src="@/assets/images/logo/medask.png"
              height="80%"
              width="58%"
            />
          </div>
          <div class="mt-3 d-flex align-items-center justify-content-start">
            <div>
              <img
                src="@/assets/images/icons/footer/address.svg"
                class="mr-50"
              />
            </div>

            <span class="py-50 font-weight-bolder text-light">
              <div>
                1st Floor, AWT Plaza, Mall Road, Saddar,
                <span class="ml-0"> Rawalpindi, Pakistan</span>
              </div>
            </span>
          </div>
        </b-col>
        <b-col md="2">
          <div class="pt-3">
            <p class="font-weight-bolder text-colorGreen">Support</p>
          </div>
          <div>
            <b-link
              :to="{ name: 'TermsOfServices' }"
              class="font-weight-bolder text-colorText"
            >
              <p class="font-weight-bolder text-light">Terms of Services</p>
            </b-link>
          </div>
          <div>
            <b-link
              :to="{ name: 'PrivacyPolicy' }"
              class="font-weight-bolder text-colorText"
            >
              <p class="font-weight-bolder text-light">Privacy Policy</p>
            </b-link>
          </div>
          <!-- <div>
            <b-link
              :to="{ name: 'Home' }"
              class="font-weight-bolder text-colorText"
            >
              <p class="py-50 font-weight-bolder text-light">
                Terms of Services
              </p>
            </b-link>
          </div> -->
        </b-col>
        <b-col md="2">
          <div class="pt-3">
            <p class="font-weight-bolder text-colorGreen">Useful Links</p>
          </div>
          <div>
            <b-link
              :to="{ name: 'Home' }"
              class="font-weight-bolder text-colorText"
            >
              <p class="font-weight-bolder text-light">Home</p>
            </b-link>
          </div>
          <div>
            <b-link
              :to="{ name: 'AboutUs' }"
              class="font-weight-bolder text-colorText"
            >
              <p class="font-weight-bolder text-light">About Us</p>
            </b-link>
          </div>
          <div>
            <b-link
              :to="{ name: 'ContactUs' }"
              class="font-weight-bolder text-colorText"
            >
              <p class="font-weight-bolder text-light">Contact Us</p>
            </b-link>
          </div>
        </b-col>
        <b-col md="2">
          <div class="pt-3">
            <p class="font-weight-bolder text-colorGreen">Our Services</p>
          </div>
          <div>
            <b-link
              :to="{ name: 'CarRental' }"
              class="font-weight-bolder text-colorText"
            >
              <p class="font-weight-bolder text-light">Car Rental</p>
            </b-link>
          </div>
          <div>
            <b-link
              :to="{ name: 'Interpreter' }"
              class="font-weight-bolder text-colorText"
            >
              <p class="font-weight-bolder text-light">Interpreter</p>
            </b-link>
          </div>
          <div>
            <b-link
              :to="{ name: 'Hotel' }"
              class="font-weight-bolder text-colorText"
            >
              <p class="font-weight-bolder text-light">Hotels</p>
            </b-link>
          </div>
          <div>
            <b-link
              :to="{ name: 'SafeTravel' }"
              class="font-weight-bolder text-colorText"
            >
              <p class="font-weight-bolder text-light">Safe Travels</p>
            </b-link>
          </div>
          <div>
            <b-link
              :to="{ name: 'Tourism' }"
              class="font-weight-bolder text-colorText"
            >
              <p class="font-weight-bolder text-light">Tourism</p>
            </b-link>
          </div>
          <div>
            <b-link
              :to="{ name: 'Insurance' }"
              class="font-weight-bolder text-colorText"
            >
              <p class="font-weight-bolder text-light">Insurance</p>
            </b-link>
          </div>
        </b-col>
        <b-col md="3">
          <div class="pt-3">
            <p class="font-weight-bolder text-colorGreen">Contact Us</p>
          </div>

          <div class="">
            <img src="@/assets/images/icons/footer/phone.svg" class="mr-50" />
            <span class="font-weight-bolder text-light"> +92 319 6048487 </span>
          </div>
          <div class="pt-1">
            <img src="@/assets/images/icons/footer/email.svg" class="mr-50" />
            <span class="font-weight-bolder text-light">
              info@medasktours.com
            </span>
          </div>
          <b-row class="pt-1 d-none d-sm-flex">
            <b-col
              lg="2"
              class="d-flex align-items-center justify-content-center"
            >
              <b-link
                href=" https://www.facebook.com/medasktours"
                target="_blank"
              >
                <div class="logo-container">
                  <img
                    src="@/assets/images/icons/footer/f2.svg"
                    class="icon-default"
                    width="40px"
                    alt="Default Logo"
                  />
                  <img
                    src="@/assets/images/icons/footer/f1.svg"
                    class="icon-hover"
                    width="40px"
                    alt="Hover Logo"
                  />
                </div>
              </b-link>
            </b-col>
            <b-col lg="2" class="d-flex align-items-center justify-content-end">
              <b-link
                href="https://www.instagram.com/medask.tours/?igsh=aG5zNG9rM3JoM25p"
                target="_blank"
                class="ml-2"
              >
                <div class="logo-container2">
                  <img
                    src="@/assets/images/icons/footer/instagram2.svg"
                    class="icon-default2"
                    alt="Default Logo"
                    width="40px"
                  />
                  <img
                    src="@/assets/images/icons/footer/instagram1.svg"
                    class="icon-hover2"
                    alt="Hover Logo"
                    width="40px"
                  />
                </div>
              </b-link>
            </b-col>
            <b-col lg="2" class="d-flex align-items-center justify-content-end">
              <b-link
                href="https://www.youtube.com/@MedAskTours"
                target="_blank"
                class="ml-2"
              >
                <div class="logo-container3">
                  <img
                    src="@/assets/images/icons/footer/youtube2.svg"
                    class="icon-default3"
                    alt="Default Logo"
                    width="40px"
                  />
                  <img
                    src="@/assets/images/icons/footer/youtube1.svg"
                    class="icon-hover3"
                    alt="Hover Logo"
                    width="40px"
                  />
                </div>
              </b-link>
            </b-col>
            <b-col lg="2" class="d-flex align-items-center justify-content-end">
              <b-link
                href="https://www.linkedin.com/company/medask/?originalSubdomain=pk"
                target="_blank"
                class="ml-2"
              >
                <div class="logo-container4">
                  <img
                    src="@/assets/images/icons/footer/linkedin2.svg"
                    class="icon-default4"
                    alt="Default Logo"
                    width="40px"
                  />
                  <img
                    src="@/assets/images/icons/footer/linkedin1.svg"
                    class="icon-hover4"
                    alt="Hover Logo"
                    width="40px"
                  />
                </div>
              </b-link>
            </b-col>
            <b-col lg="2" class="d-flex align-items-center justify-content-end">
              <b-link
                href="https://www.tiktok.com/@MedAskTours"
                target="_blank"
                class="ml-2"
              >
                <div class="logo-container5">
                  <img
                    src="@/assets/images/icons/footer/tiktok2.svg"
                    class="icon-default5"
                    alt="Default Logo"
                    width="40px"
                  />
                  <img
                    src="@/assets/images/icons/footer/tiktok1.svg"
                    class="icon-hover5"
                    alt="Hover Logo"
                    width="40px"
                  />
                </div>
              </b-link>
            </b-col>
            <b-col lg="2" class="d-flex align-items-center justify-content-end">
              <b-link
                href="https://www.x.com/@MedAskTours"
                target="_blank"
                class="ml-2"
              >
                <div class="logo-container6">
                  <img
                    src="@/assets/images/icons/footer/twitter2.svg"
                    class="icon-default6"
                    alt="Default Logo"
                    width="40px"
                  />
                  <img
                    src="@/assets/images/icons/footer/twitter1.svg"
                    class="icon-hover6"
                    alt="Hover Logo"
                    width="40px"
                  />
                </div>
              </b-link>
            </b-col>
          </b-row>
          <div class="pt-2 d-flex align-items-center d-md-none mb-1 mb-sm-0">
            <b-link
              href=" https://www.facebook.com/medasktours"
              target="_blank"
            >
              <div class="logo-container">
                <img
                  src="@/assets/images/icons/footer/f2.svg"
                  class="icon-default"
                  width="40px"
                  alt="Default Logo"
                />
                <img
                  src="@/assets/images/icons/footer/f1.svg"
                  class="icon-hover"
                  width="40px"
                  alt="Hover Logo"
                />
              </div>
            </b-link>
            <b-link
              href="https://www.instagram.com/medask.tours/?igsh=aG5zNG9rM3JoM25p"
              target="_blank"
              class="ml-2"
            >
              <div class="logo-container2">
                <img
                  src="@/assets/images/icons/footer/instagram2.svg"
                  class="icon-default2"
                  alt="Default Logo"
                  width="40px"
                />
                <img
                  src="@/assets/images/icons/footer/instagram1.svg"
                  class="icon-hover2"
                  alt="Hover Logo"
                  width="40px"
                />
              </div>
            </b-link>
            <b-link
              href="https://www.youtube.com/@MedAskTours"
              target="_blank"
              class="ml-2"
            >
              <div class="logo-container3">
                <img
                  src="@/assets/images/icons/footer/youtube2.svg"
                  class="icon-default3"
                  alt="Default Logo"
                  width="40px"
                />
                <img
                  src="@/assets/images/icons/footer/youtube1.svg"
                  class="icon-hover3"
                  alt="Hover Logo"
                  width="40px"
                />
              </div>
            </b-link>
            <b-link
              href="https://www.linkedin.com/company/medask/?originalSubdomain=pk"
              target="_blank"
              class="ml-2"
            >
              <div class="logo-container4">
                <img
                  src="@/assets/images/icons/footer/linkedin2.svg"
                  class="icon-default4"
                  alt="Default Logo"
                  width="40px"
                />
                <img
                  src="@/assets/images/icons/footer/linkedin1.svg"
                  class="icon-hover4"
                  alt="Hover Logo"
                  width="40px"
                />
              </div>
            </b-link>
            <b-link
              href="https://www.tiktok.com/@MedAskTours"
              target="_blank"
              class="ml-2"
            >
              <div class="logo-container5">
                <img
                  src="@/assets/images/icons/footer/tiktok2.svg"
                  class="icon-default5"
                  alt="Default Logo"
                  width="40px"
                />
                <img
                  src="@/assets/images/icons/footer/tiktok1.svg"
                  class="icon-hover5"
                  alt="Hover Logo"
                  width="40px"
                />
              </div>
            </b-link>
            <b-link
              href="https://www.x.com/@MedAskTours"
              target="_blank"
              class="ml-2"
            >
              <div class="logo-container6">
                <img
                  src="@/assets/images/icons/footer/twitter2.svg"
                  class="icon-default6"
                  alt="Default Logo"
                  width="40px"
                />
                <img
                  src="@/assets/images/icons/footer/twitter1.svg"
                  class="icon-hover6"
                  alt="Hover Logo"
                  width="40px"
                />
              </div>
            </b-link>
          </div>
        </b-col> </b-row
    ></b-container>
    <div style="border-top: 1px solid skyblue">
      <h6 class="py-1 text-center font-weight-bolder text-colorLightBlue">
        Copyright © 2024 MedAsk Tours
      </h6>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.my-bg {
  background: url("~@/assets/images/images/footer.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}
/* Default color class */
.logo-container {
  display: block;
}

.icon-hover {
  display: none;
}

.logo-container:hover .icon-default {
  display: none;
}

.logo-container:hover .icon-hover {
  display: block;
}
.logo-container2 {
  display: block;
}

.icon-hover2 {
  display: none;
}

.logo-container2:hover .icon-default2 {
  display: none;
}

.logo-container2:hover .icon-hover2 {
  display: block;
}

.logo-container3 {
  display: block;
}

.icon-hover3 {
  display: none;
}

.logo-container3:hover .icon-default3 {
  display: none;
}

.logo-container3:hover .icon-hover3 {
  display: block;
}
.logo-container4 {
  display: block;
}

.icon-hover4 {
  display: none;
}

.logo-container4:hover .icon-default4 {
  display: none;
}

.logo-container4:hover .icon-hover4 {
  display: block;
}
.logo-container5 {
  display: block;
}

.icon-hover5 {
  display: none;
}

.logo-container5:hover .icon-default5 {
  display: none;
}

.logo-container5:hover .icon-hover5 {
  display: block;
}
.logo-container6 {
  display: block;
}

.icon-hover6 {
  display: none;
}

.logo-container6:hover .icon-default6 {
  display: none;
}

.logo-container6:hover .icon-hover6 {
  display: block;
}
</style>
